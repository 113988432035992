function animationMobile(el, classEl, isMobile, hold = false) {
    if (isMobile) {
        if ($(el).length > 0) el.classList.add(classEl);
    } else {
        if ($(window).width() <= 768) {
            if (!hold) {
                if ($(el).length > 0) el.classList.remove(classEl);
            }
        } else {
            if ($(el).length > 0) el.classList.add(classEl);
        }
    }
}

function reveal(
    parentEl,
    el,
    elVisible,
    elOut,
    classVisible,
    classOut,
    includeMobile,
    hold = false
) {
    const windowHeight = window.innerHeight;
    let elementTop, elementBottom;
    if (parentEl) {
        elementTop = parentEl.getBoundingClientRect().top;
        elementBottom = parentEl.getBoundingClientRect().bottom;
    }

    if (elementTop < windowHeight - elVisible) {
        if (el && el.length) {
            for (let i = 0; i < el.length; i++) {
                if (classVisible) {
                    animationMobile(el[i], classVisible, includeMobile, hold);
                }
            }
        } else {
            if (parentEl && el && classVisible) {
                animationMobile(el, classVisible, includeMobile, hold);
            }
        }
    } else {
        if (!hold) {
            if (el && el.length) {
                for (let i = 0; i < el.length; i++) {
                    if (classVisible) el[i].classList.remove(classVisible);
                }
            } else {
                if (parentEl && el && $(el).length > 0 && classVisible)
                    el.classList.remove(classVisible);
            }
        }
    }

    if (elementBottom < elOut) {
        if (el && el.length) {
            for (let i = 0; i < el.length; i++) {
                if (classVisible) el[i].classList.remove(classVisible);
                // if (classOut) el[i].classList.add(classOut);
                if (classOut) {
                    animationMobile(el[i], classOut, includeMobile, hold);
                }
            }
        } else {
            if (parentEl && el && $(el).length > 0 && classVisible) {
                if (!hold) {
                    el.classList.remove(classVisible);
                }
            }
            // if (parentEl && el && classOut) el.classList.add(classOut);
            if (parentEl && el && classOut) {
                animationMobile(el, classOut, includeMobile, hold, hold);
            }
        }
    } else {
        if (el && el.length) {
            for (let i = 0; i < el.length; i++) {
                if (!hold) {
                    if (classOut) el[i].classList.remove(classOut);
                }
            }
        } else {
            if (parentEl && el && $(el).length > 0 && classOut) {
                if (!hold) {
                    el.classList.remove(classOut);
                }
            }
        }
    }
}
function listFunc() {
    //animate home
    reveal(
        $("body")[0],
        $("#sticky-header")[0],
        0,
        0,
        "animate__fadeInDown",
        "",
        true
    );

    reveal(
        $("body")[0],
        $(".hero-banner__item__content")[0],
        0,
        0,
        "animate__fadeIn",
        "",
        true
    );

    reveal(
        $(".hero-banner")[0],
        $(".slide-res-nav")[0],
        0,
        0,
        "animate__fadeIn",
        "",
        true
    );

    reveal(
        $(".article-slide")[0],
        $(".article-slide .container")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#article-home")[0],
        $("#article-home .container")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".testimonial")[0],
        $(".testimonial .testimonial__slide")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        true
    );
    // end home

    reveal(
        $("body")[0],
        $(".hero-banner__img")[0],
        0,
        0,
        "animate__fadeIn",
        "",
        true
    );

    reveal(
        $(".hero-banner")[0],
        $(".hero-banner .hero-banner__img__content")[0],
        0,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#animate-top-content")[0],
        $("#top-content")[0],
        150,
        -99999,
        "animate__fadeInUp",
        "",
        true
    );

    reveal(
        $("#service-excellence")[0],
        $("#service-excellence .content-text")[0],
        90,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#operational-excellence")[0],
        $("#operational-excellence .content-text")[0],
        90,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#industry-focused")[0],
        $("#industry-focused .content-text")[0],
        90,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#service-excellence .row")[0],
        $("#service-excellence .col-12"),
        80,
        -99999,
        "animate__fadeInUp",
        "",
        false
    );
    reveal(
        $("#operational-excellence .row")[0],
        $("#operational-excellence .col-12"),
        80,
        -99999,
        "animate__fadeInUp",
        "",
        false
    );
    reveal(
        $("#industry-focused .row")[0],
        $("#industry-focused .col-12"),
        80,
        -99999,
        "animate__fadeInUp",
        "",
        false
    );
    reveal(
        $(".events-slide")[0],
        $(".events-slide .container")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        false
    );
    reveal(
        $(".discover-courses__cates")[0],
        $(".discover-courses__cates__item"),
        100,
        -99999,
        "animate__zoomIn",
        "",
        true
    );

    reveal(
        $(".our-clients__logos")[0],
        $(".our-clients__logo"),
        80,
        -99999,
        "animate__zoomIn",
        "",
        true
    );

    reveal(
        $(".our-awards")[0],
        $(".our-awards .content-text")[0],
        150,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".enquiry-content")[0],
        $(".enquiry-content .row")[0],
        150,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".media-page")[0],
        $(".media-page .container")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $("#our-courses-block .row")[0],
        $("#our-courses-block .our-courses__item"),
        80,
        -99999,
        "animate__slideInUp",
        "",
        true,
        true
    );

    reveal(
        $("body.enquiry-page")[0],
        $("body.enquiry-page .enquiry-content")[0],
        0,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".course-content")[0],
        $(".course-content .container")[0],
        80,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".date-available")[0],
        $(".date-available .date-available__wrap")[0],
        120,
        -99999,
        "animate__slideInUp",
        "",
        true
    );

    reveal(
        $(".open-run")[0],
        $(".open-run .open-run__content")[0],
        120,
        -99999,
        "animate__slideInUp",
        "",
        true
    );
}

window.addEventListener("load", listFunc);
window.addEventListener("resize", listFunc);
window.addEventListener("orientationchange", listFunc);
window.addEventListener("scroll", listFunc);
