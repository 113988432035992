jQuery(document).ready(function ($) {
    if ($(".hero-banner__wrap").length) {
        if ($(".hero-banner__wrap").hasClass("slick-initialized")) {
            $(".hero-banner__wrap").slick("unslick");
        }

        $(".hero-banner__wrap").on(
            "init reInit afterChange",
            function (event, slick, currentSlide, nextSlide) {
                $(".hero-banner__item__content").each(function () {
                    slickNavHeroBanner($(this), $(this).find("p"));
                });
            }
        );

        $(".hero-banner__wrap").slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            fade: true,
            cssEase: "linear",
            arrows: true,
            appendDots: $(".hero-banner").find(".slide-res-dots"),
            prevArrow: $(".hero-banner").find(".slide-res-prev"),
            nextArrow: $(".hero-banner").find(".slide-res-next"),
            autoplay: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            pauseOnDotsHover: true,
            speed: 1000,
        });
    }

    // article slide on home
    // $(".article-slide__item").on(
    //     "init reInit afterChange",
    //     function (event, slick, currentSlide, nextSlide) {}
    // );

    $(".article-slide__img").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        // fade: true,
        asNavFor: ".article-slide__item",
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 800,
    });

    $(".article-slide__item").each(function () {
        $(this).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            fade: true,
            cssEase: "linear",
            asNavFor: ".article-slide__img",
            appendDots: $(this)
                .parents(".article-slide")
                .find(".slide-res-dots"),
            prevArrow: $(this)
                .parents(".article-slide")
                .find(".slide-res-prev"),
            nextArrow: $(this)
                .parents(".article-slide")
                .find(".slide-res-next"),
            autoplay: true,
            autoplaySpeed: 5000,
        });
    });

    // slide full width on home
    $(".slide-home-content").on(
        "init reInit afterChange",
        function (event, slick, currentSlide, nextSlide) {
            slideHomeContentOn($(this));
        }
    );

    $(".slide-home-content").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
    });

    // testimonial on home
    $(".testimonial__imgs").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        asNavFor: ".testimonial__item",
    });

    $(".testimonial__item").each(function () {
        $(this).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            fade: true,
            cssEase: "linear",
            asNavFor: ".testimonial__imgs",
            appendDots: $(this)
                .parents(".testimonial__wrap")
                .find(".slide-res-dots"),
            prevArrow: $(this)
                .parents(".testimonial__wrap")
                .find(".slide-res-prev"),
            nextArrow: $(this)
                .parents(".testimonial__wrap")
                .find(".slide-res-next"),
        });
    });

    //events learning solution
    $(".events-slide__imgs").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        // fade: true,
        asNavFor: ".events-slide__items",
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 800,
    });

    $(".events-slide__items").each(function () {
        $(this).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: true,
            fade: true,
            cssEase: "linear",
            asNavFor: ".events-slide__imgs",
            appendDots: $(this)
                .parents(".events-slide")
                .find(".slide-res-dots"),
            prevArrow: $(this).parents(".events-slide").find(".slide-res-prev"),
            nextArrow: $(this).parents(".events-slide").find(".slide-res-next"),
            autoplay: true,
            autoplaySpeed: 5000,
        });
    });

    // add to resize
    let windowwidth=0;
    $(window).on("load resize orientationchange", function () {
        slideHomeContentOn($(".slide-home-content"));

        if ($(".hero-banner__item__content").length) {
            slickNavHeroBanner(
                $(".hero-banner__item__content"),
                $(".hero-banner__item__content p")
            );
        }

        if ($(window).width() > 768) {
            if ($(".service-excellence__cards .row").length) {
                if (
                    $(".service-excellence__cards .row").hasClass(
                        "slick-initialized"
                    )
                ) {
                    $(".service-excellence__cards .row").slick("unslick");
                }
            }
        } else {
            if ($(".service-excellence__cards .row").length) {

                if ($(".service-excellence__cards .row").hasClass("slick-initialized")) {
                    if(windowwidth!=window.innerWidth){
                        $(".service-excellence__cards .row").slick("unslick");
                        $(".service-excellence__cards .row").each(function () {
                            $(this).addClass('already-init');
                            $(this).slick({
                                infinite: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                                arrows: false,
                            });
                        });
                    }
                    
                }else{
                    $(".service-excellence__cards .row").each(function () {
                        if((!$(this).hasClass('already-init')) || (window.innerWidth!=windowwidth)){
                            $(this).addClass('already-init');
                            $(this).slick({
                                infinite: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true,
                                arrows: false,
                            });
                        }else{
                            
                        } 
                    });
                }    
            }

            if ($(".our-courses .service-excellence__cards .row").length) {
                if (
                    $(".our-courses .service-excellence__cards .row").hasClass(
                        "slick-initialized"
                    )
                ) {
                    $(".our-courses .service-excellence__cards .row").slick(
                        "unslick"
                    );
                }
            }
        }
        windowwidth=window.innerWidth;
    });
});

function slideHomeContentOn(slideSelect) {
    var windowWidth = $(window).width();
    var containerWidth = $(".container").width();
    slideSelect
        .find(".slick-arrow")
        .css("width", windowWidth - containerWidth + "px");
}

function slickNavHeroBanner(selectBlock, selectEl) {
    var posContent = selectBlock.position();
    var posEl = selectEl.position();
    var heightEl = selectEl.height();

    if ($(window).width() > 768) {
        selectBlock
            .parents(".hero-banner")
            .find(".slide-res-nav")
            .css("top", posContent.top - 64 + "px");
    } else {
        selectEl
            .parents(".hero-banner")
            .find(".slide-res-nav")
            .css("top", posEl.top + heightEl + 40 + "px");
    }
}
