jQuery(document).ready(function ($) {
    $("body").on("click", ".btn-sub-navs", function () {
        $("#sub-nav-mobile").css("left", 0);
    });

    $("body").on("click", ".btn-back-main-navs", function () {
        $("#sub-nav-mobile").css("left", "100%");
    });

    const myDropdown = document.getElementById(
        "learning-solution-btn-dropdown"
    );
    myDropdown.addEventListener("show.bs.dropdown", (event) => {
        $("header").addClass("has-dropdown");
    });
    myDropdown.addEventListener("hide.bs.dropdown", (event) => {
        $("header").removeClass("has-dropdown");
    });

    const navOffcanvas = document.getElementById("offcanvasNavbar");
    navOffcanvas.addEventListener("hidden.bs.offcanvas", (event) => {
        $("#sub-nav-mobile").css("left", "100%");
    });

    const dontFindCollapsible = document.getElementById("collapseDontFind");
    if (dontFindCollapsible) {
        dontFindCollapsible.addEventListener("show.bs.collapse", (event) => {
            $(".dont-find").addClass("show");
            $(".btn-dont-find").addClass("active");
        });
        dontFindCollapsible.addEventListener("hide.bs.collapse", (event) => {
            $(".dont-find").removeClass("show");
            $(".btn-dont-find").removeClass("active");
        });
    }

    if ($("#datepicker").length) {
        $("#datepicker").datepicker();
    }

    calcArticleImg();
    scrollToElm();

    $(window).on("load resize orientationchange", function () {
        // setHeader();
        calcArticleImg();
        matchHeightContent();
        paginationCourses();
        dontFindMobile();
        paginationMedia();
    });
});

function setHeader() {
    var headerHeight = $("header.header").height();
    $("header.header").css("margin-bottom", "-" + headerHeight + "px");
}

function calcArticleImg() {
    var windowWidth = $(window).width();
    var containerWidth = $(".container").width();
    var marginWC = (windowWidth - containerWidth) / 2;
    if (windowWidth > 991) {
        $(".article-slide__img").css("margin-right", "-" + marginWC + "px");
        $(".article-slide__item__img--left").css(
            "margin-left",
            "-" + marginWC + "px"
        );
        $(".article-slide__item__img--right").css(
            "margin-right",
            "-" + marginWC + "px"
        );
    } else {
        $(".article-slide__img").css("margin-right", "");
        $(".article-slide__item__img--left").css("margin-left", "");
        $(".article-slide__item__img--right").css("margin-right", "");
    }
    if (windowWidth > 576) {
        $(".article-slide__img").css("width", "");
        $(".article-slide__item__img").css("width", "");
    } else {
        $(".article-slide__img").css("width", windowWidth + "px");
        $(".article-slide__item__img").css("width", windowWidth + "px");
    }

    if ($(".find-us .where").length) {
        $(".find-us .where").css({
            "padding-left": marginWC + "px",
            "padding-right": marginWC / 2 + "px",
        });
    }
}

function matchHeightContent() {
    if ($(".our-courses .card .card-title").length) {
        $(".our-courses .card .card-title").matchHeight();
    }

    if ($(".our-courses .card .card-text").length) {
        $(".our-courses .card .card-text").matchHeight();
    }

    if ($(".service-excellence .card .card-body .card-title").length) {
        $(".service-excellence .card .card-body .card-title").matchHeight();
    }
}
let pwindowwidth = 0;
let firspagerun = true;
function paginationCourses() {
    if ($(window).width() < 768) {
        if ($("#courses-pagination").length) {
            if (pwindowwidth != window.innerWidth) {
                firspagerun = true;
            }
            //$('#courses-pagination').empty();
            //$('#courses-pagination').removeData("twbs-pagination");
            $("#courses-pagination").twbsPagination({
                totalPages: Math.ceil($(".our-courses__item").length / 3),
                visiblePages: 3,
                next: "Next",
                prev: "Prev",
                // onInit: function (event, page) {},
                onPageClick: function (event, page) {
                    var endItem = page * 3;
                    var startItem = endItem - 3;
                    //$(".our-courses__item").fadeOut();
                    //$(".our-courses__item").slice(startItem, endItem).fadeIn();
                    $(".our-courses__item").css("display", "none");
                    $(".our-courses__item")
                        .slice(startItem, endItem)
                        .css("display", "initial");
                    if (!firspagerun) {
                        window.scrollTo({
                            left: 0,
                            top: $("#our-courses-block").offset().top - 80,
                            behavior: "instant",
                        });
                    }
                    firspagerun = false;
                },
            });
        }
    } else {
        $(".our-courses__item").show();
    }
    pwindowwidth = window.innerWidth;
}

function dontFindMobile() {
    if ($(window).width() <= 768) {
        $(".dont-find").addClass("show");
        $("#collapseDontFind").addClass("show");
    } else {
        $(".dont-find").removeClass("show");
        $("#collapseDontFind").removeClass("show");
    }
}

function paginationMedia() {
    if ($(".media-page__articles__pagination").length) {
        $(".media-page__articles__pagination").twbsPagination({
            totalPages: 100,
            visiblePages: 6,
            next: "Next",
            prev: "Prev",
            // onInit: function (event, page) {},
            onPageClick: function (event, page) {
                // var endItem = page * 6;
                // var startItem = endItem - 6;
                // $(".media-page__articles__item").fadeOut();
                // $(".media-page__articles__item")
                //     .slice(startItem, endItem)
                //     .fadeIn();
            },
        });
    }
}

function scrollToElm() {
    $("body").on("click", ".scrollToElm", function (event) {
        var target = $(this).attr("data-id");
        if (target.length) {
            $("html, body").animate(
                {
                    scrollTop: $(target).offset().top - 80,
                },
                500
            );
        }
    });
}
