// When the user scrolls the page, execute myFunction
window.onscroll = function () {
    headerSticky();
};

// Get the header
var header = document.getElementById("sticky-header");
var body = document.querySelector("body");
if (body.classList.contains("enquiry-page")) {
    header.classList.add("sticky");
}

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function headerSticky() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
    if (body.classList.contains("enquiry-page")) {
        header.classList.add("sticky");
    }
}
